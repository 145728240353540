import CIcon from '@coreui/icons-react';
import { CButton, CCard, CCol, CRow } from '@coreui/react';
import classNames from 'classnames';
import dayjs from 'dayjs';
import cloneDeep from 'lodash/cloneDeep';
import throttle from 'lodash/throttle';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { DateRangePicker } from 'react-date-range';
import { toast } from 'react-toastify';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { setDateRangeReports } from '../../../../actions/subscriber';
import { API_CLIENT_DEFAULT_REPORT_DATE_RANGE_CONFIGS, DATE_RANGE_PRESETS, PERIOD_TYPES } from '../../../../constants';
import { useOutsideHandling, toastError } from '../../../../utils';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from '../../../general/dropdown';
import { EventExplorerContext } from './EventExplorer';
import CLabel from '../../../migration/CLabel';
import CFormGroup from '../../../migration/CFormGroup';
import { CInputCheckbox } from '../../../migration/CInput';
import { callTokenApi } from '../../../../apiCaller';
import CSwitch from '../../../migration/CSwitch';

const DatePickerBox = ({ toggleDateBox }) => {
     const { dateRangeConfig: globalDateRangeConfig, setDateRangeConfig: setGlobalDateRangeConfig, dateScopeOptions, setChangeData, reportName } = useContext(EventExplorerContext);
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const globalDateRange = useSelector((state) => state.subscriber.dateRangeReports);
     const today = dayjs().tz(activeAccount.timeZone || undefined);
     const maxDate = today['$d'];
     const accountId = activeAccount.id;
     const presetsToDisplay = [
          [DATE_RANGE_PRESETS.LAST_7_DAYS, DATE_RANGE_PRESETS.LAST_WEEK],
          [DATE_RANGE_PRESETS.LAST_14_DAYS, DATE_RANGE_PRESETS.THIS_MONTH],
          [DATE_RANGE_PRESETS.LAST_30_DAYS, DATE_RANGE_PRESETS.LAST_MONTH],
     ];
     const initialDateRange = {
          selection: {
               startDate: globalDateRange.selection.startDate['$d'],
               endDate: globalDateRange.selection.endDate['$d'],
               key: 'selection',
          },
     };
     
     if (globalDateRangeConfig.comparePeriod && globalDateRange.compare) {
          initialDateRange.compare = {
               startDate: globalDateRange.compare.startDate['$d'],
               endDate: globalDateRange.compare.endDate['$d'],
               key: 'compare',
          };
     }
     const dateRangeSticky = globalDateRangeConfig.sticky;

     const showSegment = localStorage.getItem('llShowSegment') || window.location.search.includes('show-segment=1');

     const [focusedRange, setFocusedRange] = useState([0, 0]);
     const [dropdownOpen, setDropdownOpen] = useState(false);
     const [configData, setConfigDataState] = useState(globalDateRangeConfig);
     const [calendarValue, setCalendarValue] = useState(initialDateRange);
     const [toggleDateRangeSticky, setToggleDateRangeSticky] = useState(dateRangeSticky);
     const [isLoading, setIsLoading] = useState(false);

     const ranges = Object.values(calendarValue).map((val) => val);
     
     // const noCompare = [
     //      REPORT_NAMES.EVENT_DETAILS,
     //      REPORT_NAMES.PAGE_VIEW_DETAILS,
     //      REPORT_NAMES.CONVERSION_EVENTS,
     //      REPORT_NAMES.FORM_SUBMISSION_EVENTS,
     //      REPORT_NAMES.SESSION_DETAILS,
     //      REPORT_NAMES.USER_DETAILS,
     //      REPORT_NAMES.PERSON_DETAILS,
     //      REPORT_NAMES.COMPANY_DETAILS,
     //      REPORT_NAMES.CONVERSION_DETAILS,
     //      REPORT_NAMES.CONVERSION_NAME_DETAILS,
     //      REPORT_NAMES.ECOMMERCE_PURCHASE_TRANSACTION,
     //      REPORT_NAMES.FORM_CATEGORY_REPORT
     // ];

     const customDateWrapperRef = useRef(null);
     useOutsideHandling(customDateWrapperRef, toggleDateBox, 'mouseup');

     useEffect(() => {
          if (!configData.comparePeriod) return;

          const startDateSelection = dayjs(calendarValue.selection.startDate);
          const endDateSelection = dayjs(calendarValue.selection.endDate);
          const selectionDateSubtract = Math.ceil((endDateSelection - startDateSelection) / (1000 * 3600 * 24));
          let startDateCompare, endDateCompare;

          switch (configData.periodType) {
               case PERIOD_TYPES.PREVIOUS_PERIOD:
                    const endDate = startDateSelection.subtract(1, 'day');
                    startDateCompare = endDate.subtract(selectionDateSubtract, 'day')['$d'];
                    endDateCompare = endDate['$d'];
                    break;
               case PERIOD_TYPES.PREVIOUS_YEAR:
                    startDateCompare = startDateSelection.subtract(365, 'day')['$d'];
                    endDateCompare = endDateSelection.subtract(365, 'day')['$d'];
                    break;
               default:
                    return;
          }

          if (!startDateCompare || !endDateCompare) return;

          setCalendarValue({
               ...calendarValue,
               compare: {
                    startDate: startDateCompare,
                    endDate: endDateCompare,
                    key: 'compare',
               },
          });
     }, [calendarValue.selection, configData.comparePeriod, configData.periodType]); // eslint-disable-line react-hooks/exhaustive-deps

     const setConfigData = (values) => {
          setConfigDataState((state) => ({ ...state, ...values }));
     };

     const onChangeDateRange = (ranges) => {
          const { selection, compare } = ranges;
          let newRanges = ranges;

          if (selection) {
               if (selection.startDate > maxDate) {
                    newRanges = {
                         selection: {
                              ...selection,
                              startDate: maxDate,
                         },
                    };
               } else if (selection.endDate > maxDate) {
                    newRanges = {
                         selection: {
                              ...selection,
                              endDate: maxDate,
                         },
                    };
               }
          } else if (compare) {
               if (compare.startDate > maxDate) {
                    newRanges = {
                         compare: {
                              ...compare,
                              startDate: maxDate,
                         },
                    };
               } else if (compare.endDate > maxDate) {
                    newRanges = {
                         compare: {
                              ...compare,
                              endDate: maxDate,
                         },
                    };
               }
          }

          setCalendarValue({ ...calendarValue, ...newRanges });
          setConfigData({ datePreset: DATE_RANGE_PRESETS.CUSTOM });

          if (compare) {
               setConfigData({ periodType: PERIOD_TYPES.CUSTOM });
          }
     };

     const handleSetComparePeriod = (compare) => {
          setConfigData({ comparePeriod: compare, periodType: PERIOD_TYPES.PREVIOUS_PERIOD });

          if (!compare) {
               const newDateRange = cloneDeep(calendarValue);
               delete newDateRange['compare'];
               setCalendarValue(newDateRange);

               if (focusedRange[0] === 1) {
                    // Reset focus range
                    setFocusedRange([0, 0]);
               }
          }
     };

     const onRangeFocusChange = (range) => {
          setFocusedRange(range);
     };

     const onChangePeriodType = (value) => {
          setConfigData({ periodType: value });
     };

     const onClickDatePreset = (preset) => {
          let dateRange;

          switch (preset) {
               case DATE_RANGE_PRESETS.TODAY:
                    dateRange = {
                         startDate: today['$d'],
                         endDate: today['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_7_DAYS:
                    dateRange = {
                         startDate: today.subtract(7, 'day')['$d'],
                         endDate: today.subtract(1, 'day')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_14_DAYS:
                    dateRange = {
                         startDate: today.subtract(14, 'day')['$d'],
                         endDate: today.subtract(1, 'day')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_30_DAYS:
                    dateRange = {
                         startDate: today.subtract(30, 'day')['$d'],
                         endDate: today.subtract(1, 'day')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_WEEK:
                    const lastWeek = today.subtract(1, 'week');
                    dateRange = {
                         startDate: lastWeek.startOf('week')['$d'],
                         endDate: lastWeek.endOf('week')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.THIS_MONTH:
                    dateRange = {
                         startDate: today.startOf('month')['$d'],
                         endDate: today.endOf('month')['$d'],
                    };
                    break;
               case DATE_RANGE_PRESETS.LAST_MONTH:
                    const lastMonth = today.subtract(1, 'month');
                    dateRange = {
                         startDate: lastMonth.startOf('month')['$d'],
                         endDate: lastMonth.endOf('month')['$d'],
                    };
                    break;
               default:
                    dateRange = calendarValue.selection;
          }

          setConfigData({ datePreset: preset });
          setCalendarValue({
               ...calendarValue,
               selection: {
                    ...dateRange,
                    key: 'selection',
               },
          });
     };

     const onApplyButtonClicked = () => {
          const { selection, compare } = calendarValue;
          const newDateRange = {
               selection: {
                    startDate: dayjs(selection.startDate),
                    endDate: selection.endDate ? dayjs(selection.endDate) : dayjs(selection.startDate),
               },
          };

          if (configData.comparePeriod && compare) {
               newDateRange.compare = {
                    startDate: dayjs(compare.startDate),
                    endDate: compare.endDate ? dayjs(compare.endDate) : dayjs(compare.startDate),
               };
          }

          if (showSegment) {
               if(toggleDateRangeSticky) {
                    const dateRangeConfig = {...configData, ...newDateRange, sticky: toggleDateRangeSticky};
                     
                    const data = {
                         accountId,
                         reportName,
                         dateRangeConfig,
                    };
                    setIsLoading(true)
                    callTokenApi(`${API_CLIENT_DEFAULT_REPORT_DATE_RANGE_CONFIGS}`, 'PUT', data)
               };
          }

          setChangeData(true)
          dispatch(setDateRangeReports(newDateRange));
          // setGlobalDateRange(newDateRange);
          setGlobalDateRangeConfig(configData);
          toggleDateBox();
     };

     const handleChangeDateScope = (e) => {
          setConfigData({ dateScope: e.value });
     };

     const handleOnToggleStatus = () => {
          const newToggleSticky = !toggleDateRangeSticky; // Toggle the sticky status
          setToggleDateRangeSticky(newToggleSticky)

          const dateRangeConfig = {...configData, ...globalDateRange, sticky: newToggleSticky };

          const data = {
               accountId,
               reportName,
               dateRangeConfig,
          };
          setIsLoading(true)
          callTokenApi(`${API_CLIENT_DEFAULT_REPORT_DATE_RANGE_CONFIGS}`, 'PUT', data)
               .then((response) => {
                    if (response.status === 200) {
                         const newConfig = { ...globalDateRangeConfig, sticky: newToggleSticky};
                         setGlobalDateRangeConfig(newConfig)
                         setConfigDataState(newConfig)
                         if(newToggleSticky) {
                              toast.success('Turn on date range sticky !');
                         } else {
                              toast.success('Turn off date range sticky !');
                         }

                    } else {
                         toastError(response);
                    }
               }) 
               .finally(() => {
                    setIsLoading(false);
               });

     };
     const divClasses = classNames('custom-date-wrapper', { 'compare': configData.comparePeriod, 'date-scope': dateScopeOptions.length > 0 });

     return (
          <CCard className={divClasses} ref={customDateWrapperRef}>
               <div className="compare-period d-flex align-items-center justify-content-between">
                    <CFormGroup variant="custom-checkbox" inline>
                         <CInputCheckbox
                              custom
                              id="comparePeriod"
                              checked={configData.comparePeriod}
                              onChange={(e) => handleSetComparePeriod(e.target.checked)}
                         />
                         <CLabel variant="custom-checkbox" htmlFor="comparePeriod">
                              Compare periods
                         </CLabel>
                    </CFormGroup>
                    {configData.comparePeriod && (
                         <Dropdown>
                              <DropdownToggle>
                                   <span>{dropdownOpen ? 'Select Period' : configData.periodType}</span>
                              </DropdownToggle>
                              <DropdownMenu setDropdownOpen={setDropdownOpen}>
                                   <CIcon icon="arrow-account" className="arrow-account arrow-setting" />
                                   {Object.entries(PERIOD_TYPES).map(([key, value]) => (
                                        <div key={key} onClick={() => onChangePeriodType(value)}>
                                             <DropdownItem>{value}</DropdownItem>
                                        </div>
                                   ))}
                              </DropdownMenu>
                         </Dropdown>
                    )}
               </div>
               {dateScopeOptions.length > 1 && (
                    <div className="select-date-scope-wrapper d-flex align-items-center justify-content-between">
                         <p className="label">Apply date range to</p>
                         <div className="select-date-scope">
                              <Select
                                   classNamePrefix="react-select"
                                   name="dateScope"
                                   // className={`${typeValid ? 'is-invalid' : ''}`}
                                   options={dateScopeOptions}
                                   value={dateScopeOptions.find((item) => item.value === configData.dateScope)}
                                   placeholder="Select..."
                                   onChange={(e) => handleChangeDateScope(e)}
                              />
                         </div>
                    </div>
               )}
               <DateRangePicker
                    months={1}
                    maxDate={maxDate}
                    direction="vertical"
                    color="#3c4b64"
                    rangeColors={['#E4E5FF', '#FFF5D6']}
                    // className={divClasses}
                    scroll={{ enabled: true }}
                    ranges={ranges}
                    staticRanges={[]}
                    inputRanges={[]}
                    weekdayDisplayFormat="EEEEEE"
                    monthDisplayFormat="MMMM"
                    editableDateInputs={true}
                    showMonthAndYearPickers={true}
                    retainEndDateOnFirstSelection={true}
                    startDatePlaceholder="Start Date"
                    endDatePlaceholder="End Date"
                    onChange={onChangeDateRange}
                    focusedRange={focusedRange}
                    onRangeFocusChange={onRangeFocusChange}
               />
               <div className="presets-wrapper">
                    <div className="easy-presets">
                         <p className="label">Easy presets</p>
                         <CButton
                              className={`${configData.datePreset === DATE_RANGE_PRESETS.TODAY ? 'active' : ''}`}
                              onClick={() => onClickDatePreset(DATE_RANGE_PRESETS.TODAY)}
                         >
                              Go to Today
                         </CButton>
                    </div>
                    <div className="presets">
                         {presetsToDisplay.map((presets, i) => {
                              return (
                                   <CRow key={i}>
                                        {presets.map((preset, presetIndex) => (
                                             <CCol key={presetIndex} sm={6} className="preset-col">
                                                  <CButton
                                                       className={`${preset === configData.datePreset ? 'active' : ''}`}
                                                       onClick={() => onClickDatePreset(preset)}
                                                  >
                                                       {preset}
                                                  </CButton>
                                             </CCol>
                                        ))}
                                   </CRow>
                              );
                         })}
                    </div>
               </div>
               <div className={`bottom-buttons d-flex align-items-center ${showSegment ? 'justify-content-between' : 'justify-content-end'}`}>
                    {showSegment && (
                         <div className="wrapper-sticky">
                              <div className="d-flex align-items-center toggle-sticky">
                                   <CSwitch
                                        color={'success'}
                                        checked={toggleDateRangeSticky}
                                        value={toggleDateRangeSticky ? 'on' : 'off'}
                                        shape="pill"
                                        tabIndex="0"
                                        size="sm"
                                        onChange={handleOnToggleStatus}
                                        disabled={isLoading}
                                   />
                                   <span className="ml-2">Sticky</span>                  
                                   <Tippy
                                        className="info-icon ml-1 mt-3 date-range"
                                        content={
                                             <div className="tooltip-content">
                                                  <div>{'Applies these settings as you navigate across reports during this reporting session.'}</div>
                                             </div>
                                        }
                                        placement="bottom-start"
                                        maxWidth="640px"
                                        offset={[1, 2]}
                                        theme="left-top-back"
                                        arrow={
                                             '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 9 7" fill="none"><path d="M-5.75784e-08 2.82843L-1.07324e-07 7L9 7L3.41421 1.41421C2.15428 0.154284 -3.63306e-08 1.04662 -5.75784e-08 2.82843Z" fill="#19222B"/></svg>'
                                        }
                                   >
                                        <span className="ml-2" style={{ paddingBottom: '6px' }}>
                                             <CIcon name="iconAlertCircle" style={{ cursor: 'pointer', width: '16px', height: '20px' }} />
                                        </span>
                                   </Tippy>
                              </div>
                         </div>
                    )}
                    <CButton onClick={toggleDateBox}>Cancel</CButton>
                    <CButton color='primary' onClick={onApplyButtonClicked} disabled={isLoading}>
                         Apply
                    </CButton>
               </div>
          </CCard>
     );
};

const CustomDatePicker = () => {
     const { dateRangeConfig, showData } = useContext(EventExplorerContext);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const [dateBoxOpened, setDateBoxOpened] = useState(false);
     const dateFormat = 'MMM D, YYYY';

     const throttledToggle = useRef(
          throttle(
               () => {
                    if (!showData) {
                         return
                    }
                    setDateBoxOpened((s) => !s);
               },
               100,
               { trailing: false },
          ),
     );

     const toggleDateBox = useCallback(() => {
          throttledToggle.current();
     }, []);

     return (
          <div className={`custom-date-picker`}>
               {dateBoxOpened && <div className="date-range-overlay" onClick={toggleDateBox}></div>}
               <CButton className="date-range-wrapper" onClick={toggleDateBox}>
                    <div className="date-range">
                         {dateRangeReports.selection && (
                              <div className="d-flex align-items-center">
                                   {dateRangeConfig.datePreset && dateRangeConfig.datePreset !== DATE_RANGE_PRESETS.CUSTOM && (
                                        <span className={`preset-date-range ${dateRangeConfig.comparePeriod && dateRangeReports.compare ? 'have-compare' : ''}`}>{dateRangeConfig.datePreset}</span>
                                   )}
                                   <span className={`selection-date-range ${dateRangeConfig.comparePeriod && dateRangeReports.compare ? 'have-compare' : ''}`}>
                                        {dateRangeReports.selection.startDate.format(dateFormat)} - {dateRangeReports.selection.endDate.format(dateFormat)}
                                   </span>
                              </div>
                         )}
                         {dateRangeConfig.comparePeriod && dateRangeReports.compare && (
                              <span className="compare-date-range">
                                   Compare: {dateRangeReports.compare.startDate.format(dateFormat)} -{' '}
                                   {dateRangeReports.compare.endDate.format(dateFormat)}
                              </span>
                         )}
                    </div>
                    <CIcon icon="icon-calendar-report" className="icon-calendar-report" alt="iconCalendar" />
               </CButton>
               {dateBoxOpened && <DatePickerBox toggleDateBox={toggleDateBox} />}
          </div>
     );
};

export default CustomDatePicker;
