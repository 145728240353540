import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
     ACCORDION_TYPES,
     API_RECORD_PROFILE_SESSION,
     COMPONENT_NAME,
     DATE_SCOPE,
     DEFAULT_DATE_FORMAT,
     REPORT_NAMES,
     SUBSCRIBER_PATH,
     VARIABLE_OPERATORS_DIMENSION_REPORT,
} from '../../../../../constants';
import { callTokenApi } from '../../../../../apiCaller';
import { convertReportDuration, encodeObj, toastError } from '../../../../../utils';
import { setFlexibleMultiModal } from '../../../../../actions/common';
import TableRecordProfile from './TableRecordProfile';
import { COMPANY_RECORD_PROFILE, SESSION_ALL_PAGE_RECORD_PROFILE, SESSION_CONVERSION_RECORD_PROFILE } from './utilsCompany';
import EventExplorer from '../EventExplorer';
import Accordion from '../../../../general/accordion';
import { UserSourceDetails } from './TootleDetail';
import { formatNumber } from '../../../../../utils/UtilReport';

const RecordProfileSession = ({ id, linkedData }) => {
     const dispatch = useDispatch();
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const dateRangeReports = useSelector((state) => state.subscriber.dateRangeReports);
     const [initialValue, setInitialValue] = useState(null);
     const [accordionShow, setAccordionShow] = useState(ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY);
     const [isFecthTableEvent, setIsFecthTableEvent] = useState(false);
     const [accordionFirstLoad, setAccordionFirstLoad] = useState([]);

     let scoped = 'Session';
     let newDimensions = [{ key: 'sessionId', label: 'Session ID' }];
     const filterEvent = [
          {
               type: 'sessionId',
               value: `${id}`,
               operator: 'eq',
               optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
               scoped: 'Event',
               reportName: 'eventDetails',
               clickable: true,
          },
     ];
     const [isLoading, setIsLoading] = useState(true);
     const data = { id, accountId: activeAccount.id };

     useEffect(() => {
          if (accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_EVENT) {
               setIsFecthTableEvent(true);
          }
     }, [accordionShow])

     useEffect(() => {
          callTokenApi(API_RECORD_PROFILE_SESSION, 'POST', data)
               .then((response) => {
                    if (response.status === 200) {
                         setInitialValue(response.data.session);
                    } else {
                         toastError(response);
                    }
               })
               .finally(() => {
                    setIsLoading(false);
               });
     }, [id]); // eslint-disable-line react-hooks/exhaustive-deps

     const sessionInfo = initialValue && initialValue.newSessionInfo;

     const handleNavigateUser = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_USER,
               })
          );
     };

     const handleNavigatePerson = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_PERSON,
               })
          );
     };

     const handleNavigateCompany = (id) => {
          dispatch(
               setFlexibleMultiModal({
                    show: true,
                    attributes: { id: id },
                    component: COMPONENT_NAME.RECORD_PROFILE_COMPANY,
               })
          );
     };

     const handleLinkToOtherReport = (fieldKey, linkedData) => {
          let path = '';
          let linkedReportData;
          let filters = [];
          const reportName = REPORT_NAMES.SESSION_DETAILS;

          switch (fieldKey) {
               case 'eventCount':
                    path = SUBSCRIBER_PATH.INSIGHTS_EVENTS;
                    if (reportName === REPORT_NAMES.SESSION_DETAILS) {
                         filters.push({
                              type: 'sessionId',
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              clickable: true,
                              filterRecord: true,
                         });
                         break;
                    }
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: item.key,
                              value: `${linkedData[item.key]}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               case 'peopleCount':
                    // newDimensions = [{ key: 'personId', label: 'Person ID' }];
                    path = SUBSCRIBER_PATH.INSIGHTS_PEOPLE;
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: 'personId',
                              value: `${sessionInfo.personId}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;

               case 'revealedCompany':
                    path = SUBSCRIBER_PATH.INSIGHTS_REVEALED_COMPANIES;
                    // newDimensions = [{ key: 'companyId', label: 'Revealed Company ID' }];
                    newDimensions.forEach((item) => {
                         filters.push({
                              type: 'sessionId',
                              value: `${id}`,
                              operator: 'ct',
                              optionsOperator: VARIABLE_OPERATORS_DIMENSION_REPORT,
                              scoped: scoped,
                              reportName: reportName,
                              clickable: true,
                              filterRecord: true,
                         });
                    });

                    break;
               default:
          }

          linkedReportData = {
               filters,
               dateRange: {
                    startDate: dateRangeReports.selection.startDate.format(DEFAULT_DATE_FORMAT),
                    endDate: dateRangeReports.selection.endDate.format(DEFAULT_DATE_FORMAT),
               },
               dateScope: DATE_SCOPE.EVENT_ACTIVITY,
               segment: []
          };

          path = path.replace(':secondId', activeAccount.secondId) + '?data=' + encodeObj(linkedReportData);
          window.open(path, '_blank');
     };

     const handleSetAccordion = (value) => {
          setAccordionShow(value)
          const data = [...accordionFirstLoad]
          if (!data.includes(value)) {
               data.push(value)
          }
          setAccordionFirstLoad(data)
     }
     return (
          <div className="record-profile">
               {isLoading ? (
                    <div className="record-profile loading-profile">
                         <h1>Session Profile</h1>
                         <div className="record-profile-info">
                              <p className="event-profile-session">Session ID: </p>
                              <p className="event-profile-session">Date: </p>
                              <p className="event-profile-session">
                                   User: <span className="link-data"></span>
                              </p>
                              <p className="event-profile-session">
                                   Person: <span className="link-data"></span>
                              </p>
                              <p className="event-profile-session">
                                   Revealed Companies: <span className="link-data"></span>
                              </p>
                         </div>
                         <div className="list-count-data">
                              <div className="card box-count-data event-profile-session">
                                   <h4>Event Count</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Engaged</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Session Duration</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Pageviews</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Conversions</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Person</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Revealed Companies</h4>
                                   <p className="item-name"></p>
                              </div>
                              <div className="card box-count-data event-profile-session">
                                   <h4>Engagement Score</h4>
                                   <p className="item-name"></p>
                              </div>
                         </div>
                         <div className="page">
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Acquisition Source:</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Landing Page:</p>
                                   <p className="description"></p>
                              </div>
                              <div className="page-path-name event-profile-session">
                                   <p className="title">Exit Page:</p>
                                   <p className="description"></p>
                              </div>
                         </div>
                    </div>
               ) : (
                    <div className="record-profile">
                         <h1>Session Profile</h1>
                         {sessionInfo && (
                              <>
                                   <div className="record-profile-info">
                                        <p>Session ID: {sessionInfo.sessionId}</p>
                                        <p>Date: {sessionInfo.date}</p>
                                        <p>
                                             User:{' '}
                                             <span className="link-data" onClick={() => handleNavigateUser(sessionInfo.userId)}>
                                                  {sessionInfo.userId}
                                             </span>
                                        </p>
                                        {sessionInfo.personId ? (
                                             <>
                                                  {sessionInfo.externalId ? (
                                                       <p>
                                                            External Id:{' '}
                                                            <span className="link-data" onClick={() => handleNavigatePerson(sessionInfo.personId)}>
                                                                 {sessionInfo.externalId}
                                                            </span>
                                                       </p>
                                                  ) : (
                                                       <p>
                                                            Person:{' '}
                                                            <span className="link-data" onClick={() => handleNavigatePerson(sessionInfo.personId)}>
                                                                 {sessionInfo.person}
                                                            </span>
                                                       </p>
                                                  )}
                                             </>
                                        ) : (
                                             <p>Person: </p>
                                        )}

                                        {/* {(sessionInfo.personType === 'newPerson' && sessionInfo.source === 'customerProvided') ||
                                        (!sessionInfo.personType && sessionInfo.source === 'customerProvided') ? (
                                             <p>
                                                  External Id:{' '}
                                                  <span className="link-data" onClick={() => handleNavigatePerson(sessionInfo.personId)}>
                                                       {sessionInfo.externalId}
                                                  </span>
                                             </p>
                                        ) : (
                                             <p>
                                                  Person:{' '}
                                                  <span className="link-data" onClick={() => handleNavigatePerson(sessionInfo.personId)}>
                                                       {sessionInfo.person}
                                                  </span>
                                             </p>
                                        )} */}
                                        <p>
                                             Revealed Company:{' '}
                                             {sessionInfo.companyList.map((item, index) => {
                                                  return (
                                                       <span key={index} className="link-data" onClick={() => handleNavigateCompany(item.companyId)}>
                                                            {index < sessionInfo.companyList.length - 1 ? (
                                                                 <>{item.companyName} ,&nbsp;</>
                                                            ) : (
                                                                 <>{item.companyName}</>
                                                            )}
                                                       </span>
                                                  );
                                             })}
                                        </p>
                                   </div>
                                   <div className="list-count-data">
                                        <div className="card box-count-data">
                                             <h4>Event Count</h4>
                                             {/* {sessionInfo.eventCount > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('eventCount', linkedData)}
                                                  >
                                                       {sessionInfo.eventCount}
                                                  </p>
                                             ) : ( */}
                                             <p className="item-name">{formatNumber(sessionInfo.eventCount)}</p>
                                             {/* )} */}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Engaged</h4>
                                             <p className="item-name">{sessionInfo.countEngaged >= 1 ? 'Yes' : 'No'}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Session Duration</h4>
                                             <p className="item-name">{convertReportDuration(sessionInfo.sessionDuration)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Pageviews</h4>
                                             <p className="item-name">{formatNumber(sessionInfo.countPageView)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Conversions</h4>
                                             <p className="item-name">{formatNumber(sessionInfo.countConversion)}</p>
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Person</h4>
                                             {sessionInfo.countPerson > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('peopleCount', linkedData)}
                                                  >
                                                       {formatNumber(sessionInfo.countPerson)}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{sessionInfo.countPerson}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Revealed Companies</h4>
                                             {sessionInfo.companyList.length > 0 ? (
                                                  <p
                                                       className="item-name link-data"
                                                       onClick={() => handleLinkToOtherReport('revealedCompany', linkedData)}
                                                  >
                                                       {sessionInfo.companyList.length}
                                                  </p>
                                             ) : (
                                                  <p className="item-name">{sessionInfo.companyList.length}</p>
                                             )}
                                        </div>
                                        <div className="card box-count-data">
                                             <h4>Engagement Score</h4>
                                             <p className="item-name">{formatNumber(sessionInfo.engagementScore)}</p>
                                        </div>
                                   </div>
                                   <div className="page">
                                        <div className="page-path-name">
                                             <p className="title">Acquisition Source:</p>
                                             <div className="description">
                                                  <UserSourceDetails data={sessionInfo.acquisitionSource} />
                                             </div>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Landing Page:</p>
                                             <p className="description">{sessionInfo.landingPage}</p>
                                        </div>
                                        <div className="page-path-name">
                                             <p className="title">Exit Page:</p>
                                             <p className="description">{sessionInfo.exitPage}</p>
                                        </div>
                                   </div>
                              </>
                         )}
                    </div>
               )}
               <div className="accordion accordion-profile">
                    <TableRecordProfile
                         id={id}
                         header={COMPANY_RECORD_PROFILE}
                         reportType={'sessionCompany'}
                         tableType={'table-company'}
                         title={'Companies'}
                         accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_COMPANY}
                         accordionShow={accordionShow}
                         setAccordionShow={setAccordionShow}
                    />
                    {isLoading ? (
                         <>
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_ALL_PAGE}
                                   title={`All Pages (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_CONVERSION}
                                   title={`Conversions (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <Accordion
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_EVENT}
                                   title={`Event Details (0)`}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              ></Accordion>
                         </>
                    ) : (
                         <>
                              <TableRecordProfile
                                   id={id}
                                   header={SESSION_ALL_PAGE_RECORD_PROFILE}
                                   reportType={'sessionAllPage'}
                                   tableType={'table-all-page'}
                                   title={'All Pages'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_ALL_PAGE}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              <TableRecordProfile
                                   id={id}
                                   header={SESSION_CONVERSION_RECORD_PROFILE}
                                   reportType={'sessionConversion'}
                                   tableType={'table-conversion'}
                                   title={'Conversions'}
                                   accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_CONVERSION}
                                   accordionShow={accordionShow}
                                   setAccordionShow={setAccordionShow}
                              />
                              {sessionInfo && sessionInfo.eventCount > 0 && (
                                   <Accordion
                                        accordionType={ACCORDION_TYPES.RECORD_PROFILE_TABLE_EVENT}
                                        title={`Event Details (${sessionInfo.eventCount})`}
                                        accordionShow={accordionShow}
                                        setAccordionShow={handleSetAccordion}
                                   >
                                        
                                        {(accordionShow === ACCORDION_TYPES.RECORD_PROFILE_TABLE_EVENT || accordionFirstLoad.includes(ACCORDION_TYPES.RECORD_PROFILE_TABLE_EVENT)) && (
                                             <EventExplorer title={''} reportName={REPORT_NAMES.EVENT_DETAILS} recordProfile={true} filterRecord={filterEvent} isFecthTableEvent={isFecthTableEvent} />
                                        )}
                                   </Accordion>
                              )}
                         </>
                    )}
               </div>
          </div>
     );
};

export default RecordProfileSession;
