import React, { useRef, useMemo, useCallback, useState, useEffect } from 'react';
import { CSidebar, CSidebarBrand, CSidebarNav, CNavItem, CButton, CLink } from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams, Link, NavLink } from 'react-router-dom';
import 'tippy.js/dist/tippy.css';
import { signout } from '../../helpers/auth';
import { subscriberMyAccountNav } from '../_nav';
import { setFlexibleModal, setSidebarShow } from '../../actions/common';
import {
     setNewLayout,
     setPreviewBrowserRule,
     setShowUpgradeAccountPopup,
     setUnsavedLookupTableModal,
     setScrollSidebar,
     setReduxActiveDropdown,
} from '../../actions/subscriber';
import { getSimpleListenerNav, getActiveDestinationNav, getSimpleAnalyticNav, getActiveGoals, getActiveMonitoring } from '../../helpers/cms/subscriber';
import { defaultSubscriberAnalyticsNav, defaultSubscriberMonitoringNav, defaultSubscriberGoalsNav, subscriberNavKey } from '../_nav/subscriber';
import { SUBSCRIBER_PATH, SUBSCRIBER_MY_ACCOUNT_PATH, NOTIFICATION_STATUS, DROPDOWN_TOOLTIPS } from '../../constants';
import { TOURSTEPS, TOUR_FORCE_OPEN } from './TheTour';
import { handleToggleSidebar } from '../../utils';
import { HoverInfoIconTooltip } from '../../components/general/custom-tooltips';
import Tippy from '@tippyjs/react';
import CCreateElement from '../../components/migration/CCreateElement';
import CToggler from '../../components/migration/CToggler';
import CSidebarNavTitle from '../../components/migration/CSidebarNavTitle';
import CSidebarNavDropdown from '../../components/migration/CSidebarNavDropdown';
import CSidebarNavItem from '../../components/migration/CSidebarNavItem';
import CSidebarNavDivider from '../../components/migration/CSidebarNavDivider';
// import { useResizeObserver } from '../../helpers/customHooks';

const CSidebarNavDropdownCustom = ({
     name,
     className,
     children,
     show,
     toggleCustom,
     showTooltip = true,
     icon,
     path,
     nameButton,
     showButton,
     componentName,
     type,
}) => {
     const contentTooltip = DROPDOWN_TOOLTIPS[name];
     const [showDropdown, setShowDropdown] = useState(show);
     const { showTour, stepTour } = useSelector((state) => state.subscriber.tour);
     // const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const tourOrderNumber = showTour && stepTour === TOURSTEPS.menuCustomData.orderNumber;
     const dispatch = useDispatch();

     useEffect(() => {
          setShowDropdown(show);
     }, [show]);

     const handleToggle = () => {
          if (typeof toggleCustom === 'function') {
               toggleCustom();
          } else {
               if (name === 'Manage Reports') {
                    dispatch(setReduxActiveDropdown('Custom Reports'));

                    // const newPath = SUBSCRIBER_PATH.SAVED_REPORTS.replace(':secondId', activeAccount.secondId)

                    // if (path === newPath) {
                    //      dispatch(setReduxActiveDropdown('Saved Reports'));
                    // }
               }
               setShowDropdown(!showDropdown);
          }
     };

     return (
          <li className={`sidebar-nav-dropdown custom ${showDropdown || tourOrderNumber ? 'c-show' : ''} ${className}`}>
               <CLink {...(path && { as: NavLink })} className="sidebar-nav-dropdown-toggle" active={type === 'dropdown' && showDropdown} onClick={handleToggle} to={path}>
                    {icon && <CIcon icon={icon} className="mr-2" />}
                    {name}
                    {showTooltip && contentTooltip && (
                         <>
                              <Tippy
                                   content={<p>{contentTooltip}</p>}
                                   placement="right-start"
                                   theme="white"
                                   arrow={
                                        '<svg viewBox="0 0 6 13"><g id="b671136e-83cf-4fb9-b98e-be51e54c953e" data-name="Layer 2"><g id="b5fae9b2-5e0b-421a-8a35-db7330bc1b57" data-name="Layer 1"><g id="b24f8614-20ef-4cec-a50a-7ced35d16814" data-name="Layer 2"><g id="fa01feaf-579e-4c20-ba60-43d232f559d5" data-name="Layer 1-2"><path d="M6,0H4.14c-7,0-3,6.06-3,6.06L6,13V0Z" style="fill:#19222b"></path></g></g></g></g></svg>'
                                   }
                                   offset={[5, 12]}
                              >
                                   <i>
                                        <CIcon icon="icon-info" width={11} height={11}></CIcon>
                                   </i>
                              </Tippy>
                         </>
                    )}
               </CLink>
               <ul className="sidebar-nav-dropdown-items">
                    {showButton && (
                         <li
                              className="sidebar-nav-item btn-add-wrapper"
                              onClick={() =>
                                   dispatch(
                                        setFlexibleModal({
                                             show: true,
                                             component: componentName,
                                        })
                                   )
                              }
                              style={{ marginLeft: '10px' }}
                         >
                              {/* eslint-disable jsx-a11y/anchor-is-valid */}
                              <a className="sidebar-nav-link">
                                   <CIcon icon="icon-plus" className="sidebar-nav-icon" />
                                   {nameButton}
                              </a>
                         </li>
                    )}

                    {children}
               </ul>
          </li>
     );
};

const CNavItemGroups = ({
     name,
     className,
     show,
     toggleCustom,
     showTooltip = true,
     icon,
     componentName,
     showButton,
     nameButton,
     path,
     data,
     noData,
}) => {
     const contentTooltip = DROPDOWN_TOOLTIPS[name];
     const [showDropdown, setShowDropdown] = useState(show);
     const { showTour, stepTour } = useSelector((state) => state.subscriber.tour);
     const dispatch = useDispatch();
     const tourOrderNumber =
          showTour && (stepTour === TOURSTEPS.menuActiveListeners.orderNumber || stepTour === TOURSTEPS.menuCustomData.orderNumber);

     useEffect(() => {
          setShowDropdown(show);
     }, [show]);

     const handleToggle = () => {
          if (typeof toggleCustom === 'function') {
               toggleCustom();
          } else {
               setShowDropdown(!showDropdown);
          }
     };

     return (
          <li className={`sidebar-nav-dropdown custom ${showDropdown || tourOrderNumber ? 'c-show' : ''} ${className}`}>
               <CLink {...(path && { as: Link })} className="sidebar-nav-dropdown-toggle" onClick={handleToggle} to={path}>
                    {icon && <CIcon icon={icon} className="mr-2" />}
                    {name}
                    {showTooltip && contentTooltip && (
                         <div className="tooltip-wrapper">
                              <HoverInfoIconTooltip>
                                   <p>{contentTooltip}</p>
                              </HoverInfoIconTooltip>
                         </div>
                    )}
               </CLink>
               <ul className="sidebar-nav-dropdown-items sidebar-nav-groups-items">
                    {showButton && (
                         <li
                              className="sidebar-nav-item btn-add-wrapper"
                              onClick={() =>
                                   dispatch(
                                        setFlexibleModal({
                                             show: true,
                                             component: componentName,
                                        })
                                   )
                              }
                         >
                              {/* eslint-disable jsx-a11y/anchor-is-valid */}
                              <a className="sidebar-nav-link">
                                   <CIcon icon="icon-plus" className="sidebar-nav-icon" />
                                   {nameButton}
                              </a>
                         </li>
                    )}
                    {data.map((groups, i) => (
                         <div key={i} className="groups-nav-items">
                              <div className="container">
                                   <div className="text-divider">{groups[0].category}</div>
                              </div>
                              {groups.length > 1 ? (
                                   groups.slice(1).map((item, ind) => (
                                        <CNavItem key={ind} className={`sidebar-nav-item ${item.className}`}>
                                             <CLink {...(item.to && { as: NavLink })} className="sidebar-nav-link" to={item.to}>
                                                  {item.icon && item.icon.src ? (
                                                       <img src={item.icon.src} className={item.icon.className} alt={item.name} />
                                                  ) : (
                                                       <CIcon icon={item.icon} className="sidebar-nav-icon" />
                                                  )}
                                                  {item.name}
                                             </CLink>
                                        </CNavItem>
                                   ))
                              ) : (
                                   <span className="no-data">{noData}</span>
                              )}
                         </div>
                    ))}
               </ul>
          </li>
     );
};

const SubscriberMyAccountSidebar = () => {
     return (
          <>
               <CSidebarNav className="my-account-nav">
                    <CCreateElement
                         items={subscriberMyAccountNav}
                         components={{
                              CSidebarNavDivider,
                              CSidebarNavDropdown,
                              CSidebarNavItem,
                              CSidebarNavTitle,
                         }}
                    />
                    <CNavItem className="sidebar-nav-item" onClick={signout}>
                         <div className="sidebar-nav-link">
                              <CIcon icon="cil-account-logout" className="sidebar-nav-icon" />
                              Sign out
                         </div>
                    </CNavItem>
               </CSidebarNav>
          </>
     );
};

const SubscriberSidebarV2 = () => {
     const history = useHistory();
     const location = useLocation();
     const dispatch = useDispatch();
     const sidebarRef = useRef(null);
     const customScrollRef = useRef(null);
     const navigationListAnalyticsRef = useRef(null);
     const navigationListListenersRef = useRef(null);
     const navigationListDestinationsRef = useRef(null);
     const navigationListMonitoringRef = useRef(null);
     const navigationListConsentPrivacyRef = useRef(null);
     const navigationListGoals = useRef(null);

     const externalData = useSelector((state) => (state.subscriber.accountExternalData ? state.subscriber.accountExternalData.sources : null));
     const { showTour, stepTour } = useSelector((state) => state.subscriber.tour);
     const activeAccount = useSelector((state) => state.subscriber.activeAccount);
     const activeListener = useSelector((state) => state.subscriber.activeListener);
     const activeListeners = useSelector((state) => state.subscriber.activeListeners);
     const nativeECommerce = useSelector((state) => state.subscriber.nativeECommerce);
     const elementJustActivated = useSelector((state) => state.subscriber.elementJustActivated);
     const activeDestinations = useSelector((state) => state.subscriber.activeDestinations);
     // const activeGoals = useSelector((state) => state.subscriber.activeGoals);
     const monitoringNotifications = useSelector((state) => state.subscriber.monitoringNotifications);
     const isLookupTableUnsaved = useSelector((state) => state.subscriber.unsavedLookupTableModal.unsaved);
     const reduxActiveDropdown = useSelector((state) => state.subscriber.reduxActiveDropdown);
     const { showPopup: showPreviewBrowserPopup } = useSelector((state) => state.subscriber.previewBrowserRule);
     const {
          activeMainNavItem: reduxActiveMainNavItem,
          forceShowNavChild: reduxForceShowNavChild,
          forceMinimizeNav: reduxForceMinimizeNav,
     } = useSelector((state) => state.subscriber.newLayout);
     const customReports = useSelector((state) => state.subscriber.customReport.reports);
     const indexTour =
          showTour &&
          (stepTour === TOURSTEPS.activeGTMDestinationsWizard.orderNumber ||
               stepTour === TOURSTEPS.accordionGTMDestinationsWizard.orderNumber ||
               stepTour === TOURSTEPS.contentGTMDestinationsWizard.orderNumber ||
               stepTour === TOURSTEPS.addMoreDestinationsWizard.orderNumber ||
               stepTour === TOURSTEPS.createDestinationsWizard.orderNumber);
     // ||stepTour === TOURSTEPS.endDestinationWizard.orderNumber

     const locationPathName = history.location.pathname;
     const showMyAccount = Object.entries(SUBSCRIBER_MY_ACCOUNT_PATH).some(([_, value]) => location.pathname.includes(value));
     const monitoringUnreadCount = monitoringNotifications
          ? monitoringNotifications.filter((item) => item.status === NOTIFICATION_STATUS.UNREAD).length
          : 0;

     const [activeDropdown, setActiveDropdown] = useState('');
     const [activeMainNavItem, setActiveMainNavItem] = useState('');
     const [activeSubNavItem, setActiveSubNavItem] = useState('');
     const scrollDistance = useSelector((state) => state.subscriber.scrollbars.scrollDistance);
     const showScrollDown = useSelector((state) => state.subscriber.scrollbars.showScrollDown);
     const showScrollTop = useSelector((state) => state.subscriber.scrollbars.showScrollTop);
     const [menuItemActive, setMenuItemActive] = useState(activeSubNavItem);
     // const [isNavChild,setIsNavChild] = useState("")
     const [scrollTop, setScrollTop] = useState(0);
     const [activeComponent, setActiveComponent] = useState(null); // eslint-disable-line
     // const menuItemActiveListener = menuItemActive && activeListeners && activeListeners.some((el) => el.type === menuItemActive) ? menuItemActive : null;
     const menuItemActiveListener = menuItemActive && menuItemActive;
     let reportId = useParams();
     function getAllNames(data) {
          return data.reduce((result, item) => {
               if (item.name) {
                    result.push(item.name);
               }
               if (item._children) {
                    result.push(...getAllNames(item._children));
               }
               return result;
          }, []);
     }

     const allNameAnalytics = getAllNames(defaultSubscriberAnalyticsNav);
     const menuItemActiveAnalytic =
          menuItemActive && allNameAnalytics && allNameAnalytics.some((el) => el === menuItemActive) ? menuItemActive : null;
     const setMenuWithLink = () => {
          const locationPathName = history.location.search;
          let activeDropdown = menuItemActive;

          if (locationPathName.includes('data=')) {
               activeDropdown = menuItemActiveAnalytic;
          } else if (!activeDropdown) {
               activeDropdown = activeSubNavItem;
          }

          const idTimeout = setTimeout(() => {
               scrollListenersHighlight();
          }, 100);

          setActiveDropdown(activeDropdown);

          return () => {
               clearTimeout(idTimeout);
          };
     };

     useEffect(setMenuWithLink, [history.location, menuItemActiveAnalytic, menuItemActiveListener]); // eslint-disable-line react-hooks/exhaustive-deps

     const updateSidebarNav = () => {
          if (reduxActiveMainNavItem || reduxForceMinimizeNav) {
               setActiveMainNavItem(reduxActiveMainNavItem);
               const toggleForceShowNavChild =
                    reduxActiveMainNavItem && ![subscriberNavKey.INSIGHTS, ''].includes(reduxActiveMainNavItem) ? true : false;
               const analyticTour = showTour && stepTour === TOURSTEPS.mainMenuAnalytics.orderNumber ? true : false;
               let minimizeNav = true;
               if (reduxForceMinimizeNav) {
                    minimizeNav = reduxForceMinimizeNav;
               } else if (window.innerWidth >= 1430) {
                    minimizeNav = false;
               }
               handleToggleSidebar({
                    activeMainNavItem: reduxActiveMainNavItem,
                    toggleShowNavChild: true,
                    forceShowNavChild: analyticTour ? analyticTour : toggleForceShowNavChild,
                    toggleMinimizeNav: true,
                    forceMinimizeNav: minimizeNav,
               });
               // checkNavChild()
          }
     };
     useEffect(updateSidebarNav, [reduxActiveMainNavItem, stepTour, reduxForceMinimizeNav]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleResize = () => {
          if (window.innerWidth < 1430) {
               handleToggleSidebar({
                    toggleMinimizeNav: true,
                    forceMinimizeNav: true,
               });
          }
     };

     useEffect(() => {
          window.addEventListener('resize', handleResize);
          return () => {
               window.removeEventListener('resize', handleResize);
          };
     }, []);

     function extractToAttributes(data) {
          const toAttributes = [];

          function extractFromItem(item) {
               if (item && item.to) {
                    toAttributes.push(item.to);
               }

               for (const key in item) {
                    if (typeof item[key] === 'object') {
                         extractFromItem(item[key]);
                    } else if (Array.isArray(item[key])) {
                         item[key].forEach((child) => {
                              extractFromItem(child);
                         });
                    }
               }
          }

          data.forEach((item) => {
               extractFromItem(item);
          });

          return toAttributes;
     }

     useEffect(() => {
          if (reduxActiveDropdown) {
               let newActiveMainNavItem = null;

               if (listUrlInsights.includes(window.location.pathname)) {
                    newActiveMainNavItem = subscriberNavKey.INSIGHTS;
               }
               if (listUrlGoals.includes(window.location.pathname)) {
                    newActiveMainNavItem = subscriberNavKey.GOALS;
               }
               if (listUrlSources.includes(window.location.pathname)) {
                    newActiveMainNavItem = subscriberNavKey.LISTENERS;
               }
               if (listUrlDestinations.includes(window.location.pathname)) {
                    newActiveMainNavItem = subscriberNavKey.DESTINATIONS;
               }
               if (listUrlHealth.includes(window.location.pathname)) {
                    newActiveMainNavItem = subscriberNavKey.MONITORING;
               }
               if (listUrlConsent.includes(window.location.pathname)) {
                    newActiveMainNavItem = subscriberNavKey.CONSENT_PRIVACY;
               }

               if (newActiveMainNavItem !== null) {
                    setActiveMainNavItem(newActiveMainNavItem);
               }

               setActiveDropdown(reduxActiveDropdown);
               setActiveSubNavItem(reduxActiveDropdown);
               dispatch(setReduxActiveDropdown(''));
          }
     }, [reduxActiveDropdown]); //eslint-disable-line

     // const [checkScrollSidebarFirst, setCheckScrollSidebarFirst] = useState(true);
     // const scrollListenersSidebar = () => {
     //     const elementScroll = document.querySelector('.sidebar-items-wrap');
     //     if (elementScroll) {
     //         setTimeout(() => {
     //           const activeItemNav = document.querySelector('.nav-item.sidebar-nav-item .sidebar-nav-link.active');
     //           const activeItemNavRect = activeItemNav && activeItemNav.getBoundingClientRect();
     //           if (activeItemNavRect && checkScrollSidebarFirst) {
     //                elementScroll.scrollTo({ top: (activeItemNavRect.top + elementScroll.scrollTop) - 300, behavior: 'smooth' });
     //                setCheckScrollSidebarFirst(false);
     //           }
     //         }, 1000);
     //     }
     // };

     const scrollListenersHighlight = () => {
          const scrollElement = document.querySelector('.sidebar-items-wrap .sidebar-nav-dropdown.custom.c-show.nav-items');
          const element = document.querySelector('.sidebar-items-wrap');
          if (scrollElement) {
               const highlightElement = scrollElement.querySelector('a.sidebar-nav-link.active');
               if (highlightElement) {
                    const activeElementRect = highlightElement.getBoundingClientRect();
                    const sidebarWrapRect = scrollElement.getBoundingClientRect();
                    const scrollDistanceY = activeElementRect.top - sidebarWrapRect.top;
                    element.scrollTo({ left: 0, top: scrollDistanceY - 300, behavior: 'smooth' });
               }
          }
     };

     const handleActiveDashboard = () => {
          setActiveMainNavItem(subscriberNavKey.INSIGHTS);
          setActiveSubNavItem('');
          handleToggleSidebar({
               activeMainNavItem: subscriberNavKey.INSIGHTS,
               toggleShowNavChild: true,
               toggleMinimizeNav: true,
               // forceMinimizeNav: locationPathName === '/' ? false : true,
               forceMinimizeNav: true,
               forceShowNavChild: true,
          });
          // checkNavChild()
     };

     // const checkNavChild = () => {
     // const sidebar = document.querySelector('.sidebar-v2');
     // const sidebarClassList = sidebar.classList;
     // const checkSidebarShowNavChild = sidebarClassList.contains('show-nav-child');
     // setIsNavChild(checkSidebarShowNavChild)
     // }

     const currentAccountId = useRef(activeAccount.id);
     useEffect(() => {
          if (currentAccountId && currentAccountId.current && activeAccount.id && currentAccountId.current !== activeAccount.id) {
               handleActiveDashboard();
          }
          currentAccountId.current = activeAccount.id;
     }, [activeAccount]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (locationPathName === `/${reportId.secondId}` && !showTour) {
               handleActiveDashboard();
          }
     }, [locationPathName, showTour]); // eslint-disable-line react-hooks/exhaustive-deps

     const handleSetFlexibleModal = (component, category) => {
          dispatch(
               setFlexibleModal({
                    show: true,
                    component,
                    category,
               })
          );
     };

     //Check status Ecommerce menu Goals
     // const [checkEcommerceStatus, setCheckEcommerceStatus] = useState(false);
     // const callCheckEcommerceStatus = async () => {
     //      if(checkEcommerceStatus){
     //           console.log(123);
     //      }
     //      try {
     //           const endpoint = `${API_CLIENT_ACCOUNT_RULES}/${activeAccount.id}?group=triggers`;
     //           const response = await callTokenApi(endpoint, 'GET');

     //           if (response) {
     //                if (response.data && response.data.rules) {
     //                     const ecommerceStatus = response.data.rules.some((rule) => rule.name === 'Ecommerce Events');
     //                     setCheckEcommerceStatus(ecommerceStatus);
     //                }
     //           } else {
     //                console.error('No response received.');
     //                return false;
     //           }
     //      } catch (err) {
     //           console.error('Error fetching data:', err);
     //           return false;
     //      }
     // };

     const handleShowNavChild = (navItem) => {
          setActiveMainNavItem(navItem);
          if (reduxActiveMainNavItem && !showTour) {
               dispatch(setNewLayout({ activeMainNavItem: null }));
          }

          if (showTour) {
               if (navItem && !['', subscriberNavKey.INSIGHTS].includes(navItem)) {
                    handleToggleSidebar({ activeMainNavItem: navItem, toggleShowNavChild: true, forceShowNavChild: true });

                    if (locationPathName === `/${reportId.secondId}`) {
                         window.dispatchEvent(new Event('resize')); // Resize chart in dashboard when show nav child
                    }
               }
          } else {
               if (navItem) {
                    handleToggleSidebar({
                         activeMainNavItem: navItem,
                         toggleShowNavChild: true,
                         forceShowNavChild: true,
                         toggleMinimizeNav: true,
                         forceMinimizeNav: true,
                    });

                    if (locationPathName === `/${reportId.secondId}`) {
                         window.dispatchEvent(new Event('resize')); // Resize chart in dashboard when show nav child
                    }
               }
          }

          if (navItem === subscriberNavKey.DESTINATIONS) {
               setActiveSubNavItem(subscriberNavKey.DESTINATIONS);
          }
          // setScrollDistance(0);
     };

     const subscriberSidebarNav = [
          {
               _tag: 'CSidebarNavItem',
               name: subscriberNavKey.INSIGHTS,
               icon: 'iconAnalytics',
               className: `has-child ${activeMainNavItem === subscriberNavKey.INSIGHTS ? 'active' : ''}`,
               onClick: () => handleShowNavChild(subscriberNavKey.INSIGHTS),
          },
          {
               _tag: 'CSidebarNavItem',
               name: subscriberNavKey.GOALS,
               icon: 'iconCircleStartSolid',
               className: `has-child ${activeMainNavItem === subscriberNavKey.GOALS ? 'active' : ''}`,
               onClick: () => handleShowNavChild(subscriberNavKey.GOALS),
          },
          {
               _tag: 'CSidebarNavItem',
               name: subscriberNavKey.LISTENERS,
               icon: 'cil-layers',
               className: `has-child ${activeMainNavItem === subscriberNavKey.LISTENERS ? 'active' : ''}`,
               onClick: () => handleShowNavChild(subscriberNavKey.LISTENERS),
          },
          {
               _tag: 'CSidebarNavItem',
               name: subscriberNavKey.DESTINATIONS,
               icon: 'iconDestinations',
               className: `has-child ${activeMainNavItem === subscriberNavKey.DESTINATIONS ? 'active' : ''}`,
               onClick: () => handleShowNavChild(subscriberNavKey.DESTINATIONS),
          },
          {
               _tag: 'CSidebarNavItem',
               name: subscriberNavKey.MONITORING,
               icon: 'iconMonitoring',
               className: `has-child ${activeMainNavItem === subscriberNavKey.MONITORING ? 'active' : ''}`,
               onClick: () => handleShowNavChild(subscriberNavKey.MONITORING),
          },
          {
               _tag: 'CSidebarNavItem',
               name: subscriberNavKey.CONSENT_PRIVACY,
               icon: 'iconShieldQuartered',
               className: `has-child ${activeMainNavItem === subscriberNavKey.CONSENT_PRIVACY ? 'active' : ''}`,
               onClick: () => handleShowNavChild(subscriberNavKey.CONSENT_PRIVACY),
          },
     ];

     const getSubscriberConsentPrivacyNav = useCallback(() => {
          const defaultSubscriberNav = [
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Consent Settings',
                    to: SUBSCRIBER_PATH.CONSENT_RULES.replace(':secondId', reportId.secondId),
                    exact: false,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Data Categories',
                    to: SUBSCRIBER_PATH.DATA_CATEGORIES.replace(':secondId', reportId.secondId),
                    exact: false,
               },
               // {
               //      _tag: 'CSidebarNavItem',
               //      name: 'Personal Data',
               //      to: SUBSCRIBER_PATH.PERSONAL_DATA,
               //      exact: false,
               // },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Consent Logs',
                    to: SUBSCRIBER_PATH.CONSENT_LOGS.replace(':secondId', reportId.secondId),
                    exact: false,
               },
               {
                    _tag: 'CSidebarNavItem',
                    name: 'Data Requests',
                    to: SUBSCRIBER_PATH.DATA_REQUESTS.replace(':secondId', reportId.secondId),
                    exact: false,
               },
          ];

          return [...defaultSubscriberNav]; // eslint-disable-next-line
     }, [reportId.secondId]);
     const subscriberConsentPrivacyNav = useMemo(() => getSubscriberConsentPrivacyNav(), [getSubscriberConsentPrivacyNav]);
   
     const getSubscriberDataNav = useCallback(() => {
          // const listenerClassName = `${showTour && stepTour === TOURSTEPS.menuActiveListeners.orderNumber ? ' highlight-item' : ''} has-button-add`;
          const listenerNav = getSimpleListenerNav({
               elementJustActivated,
               activeListeners,
               menuItemActive,
               menuItemActiveListener,
               activeDropdown,
               setActiveDropdown,
               setMenuItemActive,
               externalData,
               reportId
          });

          return [...listenerNav]; // eslint-disable-next-line
     }, [activeListeners, menuItemActive, activeDropdown, elementJustActivated, showTour, stepTour, activeMainNavItem, externalData]); // eslint-disable-line react-hooks/exhaustive-deps

     const subscriberDataNav = useMemo(() => getSubscriberDataNav(activeListeners), [activeListeners, getSubscriberDataNav]);

     const getSubscriberDestinationNav = useCallback(() => {
          // const defaultNav = [
          //      {
          //           _tag: 'CSidebarNavItem',
          //           name: 'Triggers & Conversions',
          //           to: SUBSCRIBER_PATH.TRIGGER_AND_CONVERSIONS,
          //           exact: false,
          //      },
          // ];

          const destinationClassName = `${
               showTour && stepTour === TOURSTEPS.menuActiveDestinations.orderNumber ? ' highlight-item' : ''
          } has-button-add`;
          const activeDestinationsNav = getActiveDestinationNav({
               activeDestinations,
               showChild: [menuItemActive, activeMainNavItem].includes(subscriberNavKey.DESTINATIONS),
               className: destinationClassName,
               elementJustActivated,
               handleSetFlexibleModal,
               showTour,
               reportId
          });

          return [...activeDestinationsNav];
     }, [menuItemActive, activeDestinations, elementJustActivated, showTour, stepTour]); // eslint-disable-line react-hooks/exhaustive-deps

     const subscriberDestinationNav = useMemo(
          () => getSubscriberDestinationNav(activeDestinations),
          [activeDestinations, getSubscriberDestinationNav]
     );

     const getSubscriberGoals = useCallback(() => {
          const activeGoalsNav = getActiveGoals({
               // activeGoals,
               activeListeners,
               nativeECommerce,
               defaultSubscriberGoalsNav,
               // showChild: [menuItemActive, activeMainNavItem].includes(subscriberNavKey.GOALS),
               elementJustActivated,
               handleSetFlexibleModal,
               reportId
          });
          return [...activeGoalsNav];
     }, [menuItemActive, elementJustActivated, activeListeners, nativeECommerce]); // eslint-disable-line react-hooks/exhaustive-deps

     const subscriberGoals = useMemo(() => getSubscriberGoals(activeDestinations), [activeDestinations, getSubscriberGoals]);

     const getSubscriberMonitoringNav = useCallback(() => {
          const activeMonitoring = getActiveMonitoring({
               defaultSubscriberMonitoringNav,
               menuItemActive,
               monitoringUnreadCount,
               reportId
          });

          return [...activeMonitoring] // eslint-disable-next-line
     }, [menuItemActive, monitoringUnreadCount]);
     const subscriberMonitoringNav = useMemo(() => getSubscriberMonitoringNav(), [getSubscriberMonitoringNav]);

     const getSubscriberAnalyticsNav = useCallback(() => {
          const analyticNav = getSimpleAnalyticNav({
               defaultSubscriberAnalyticsNav,
               menuItemActive,
               menuItemActiveAnalytic,
               activeDropdown,
               setActiveDropdown,
               customReports,
               reportId,
          });

          return [...analyticNav];
     }, [menuItemActive, activeDropdown, customReports]); // eslint-disable-line react-hooks/exhaustive-deps
     const subscriberAnalyticsNav = useMemo(() => getSubscriberAnalyticsNav(), [getSubscriberAnalyticsNav]);

     const activeSidebarMenuItem = () => {
          const listNav = [
               {
                    name: subscriberNavKey.LISTENERS,
                    data: subscriberDataNav,
               },
               {
                    name: subscriberNavKey.GOALS,
                    data: subscriberGoals,
               },
               {
                    name: subscriberNavKey.CONSENT_PRIVACY,
                    data: subscriberConsentPrivacyNav,
               },
               {
                    name: subscriberNavKey.DESTINATIONS,
                    data: subscriberDestinationNav,
               },
               {
                    name: subscriberNavKey.MONITORING,
                    data: defaultSubscriberMonitoringNav,
               },
               {
                    name: subscriberNavKey.INSIGHTS,
                    data: defaultSubscriberAnalyticsNav,
               },
          ];
          let navChildShow = '';

          for (let index = 0; index < listNav.length; index++) {
               const item = listNav[index];
               const navItemName = item.name;
               const subscriberNav = item.data;

               const sidebarNavItemActive = subscriberSidebarNav.find(
                    (navItem) => navItem.to && navItem.to !== `/${reportId.secondId}` && locationPathName.includes(navItem.to)
               );

               const checkNavActive = subscriberNav.some((navItem) => {
                    if (navItem.to && locationPathName.replace(/\/+$/, '') === navItem.to.replace(':secondId', reportId.secondId)) {
                         return true;
                    } else if (navItem._children) {
                         return navItem._children.some((navChildItem) => {
                              // if (navChildItem.name && navChildItem.path && locationPathName.includes(navChildItem.path)) {
                              //      setMenuItemActive(navChildItem.name);
                              //      return true;
                              // }

                              const navChildItemSecondId = navChildItem.to ? navChildItem.to.replace(':secondId', reportId.secondId) : navChildItem.to

                              if (navChildItem.name && navChildItemSecondId && locationPathName.includes(navChildItemSecondId)) {
                                   setMenuItemActive(navItem.name);
                                   return true;
                              }
                              if (navItem._children && navItem._children[0]._children) {
                                   // eslint-disable-next-line array-callback-return
                                   return navItem._children[0]._children.some((navChildItem) => {
                                        if (navChildItem.name && navChildItemSecondId && locationPathName.includes(navChildItemSecondId)) {
                                             setMenuItemActive(navItem.name);
                                             return true;
                                        }
                                   });
                              }
                              return false;
                         });
                    } else if (navItem.data) {
                         // eslint-disable-next-line
                         return navItem.data.some((group) => {
                              // eslint-disable-next-line array-callback-return
                              return (
                                   group.length > 1 &&
                                   //eslint-disable-next-line
                                   group.slice(1).some((item) => {
                                        if (item.to && item.name && locationPathName.includes(item.to)) {
                                             setMenuItemActive(navItem.name);
                                             return true;
                                        }
                                   })
                              );
                         });
                    }
                    return false;
               });

               if (sidebarNavItemActive) {
                    navChildShow = sidebarNavItemActive.name;
               } else if (reduxActiveMainNavItem || (!showTour && activeMainNavItem)) {
                    navChildShow = reduxActiveMainNavItem || activeMainNavItem;
               } else if (checkNavActive) {
                    navChildShow = navItemName;
               }
          }

          if (navChildShow && navChildShow !== '') {
               handleShowNavChild(navChildShow);
          }

          scrollListenersHighlight(); //scroll to nav item Highlight
     };
     // eslint-disable-next-line
     useEffect(activeSidebarMenuItem, [
          subscriberDataNav,
          subscriberConsentPrivacyNav,
          subscriberDestinationNav,
          defaultSubscriberMonitoringNav,
          subscriberAnalyticsNav,
          activeListener,
          subscriberGoals,
     ]); // eslint-disable-line react-hooks/exhaustive-deps

     const onClickUpgrade = () => {
          if (isLookupTableUnsaved) {
               dispatch(
                    setUnsavedLookupTableModal({
                         show: true,
                         onAccept: () => dispatch(setShowUpgradeAccountPopup(true)),
                    })
               );
          } else {
               dispatch(setShowUpgradeAccountPopup(true));
          }
     };

     const fetchSubNavItemActive = () => {
          setMenuItemActive(activeSubNavItem);
     };
     useEffect(fetchSubNavItemActive, [activeSubNavItem, setMenuItemActive]);

     const executeSidebarScroll = () => {
          if (sidebarRef !== null && sidebarRef.current !== null) {
               sidebarRef.current.scrollIntoView();
          }
     };

     const handleShowActiveNavItem = (pathName) => {
          const listNavItems = {
               subscriberDataNav,
               subscriberConsentPrivacyNav,
               subscriberDestinationNav,
               subscriberMonitoringNav,
               subscriberAnalyticsNav,
               subscriberGoals,
          };
          let newActiveSubNavItem = '';
          let activeNavKey = null;

          for (const navKey in listNavItems) {
               const listNav = listNavItems[navKey];
               let checkActiveNav = false;

               for (let index = 0; index < listNav.length; index++) {
                    const navItem = listNav[index];

                    if (navItem.to && pathName.includes(navItem.to) && navItem.to !== `/${reportId.secondId}`) {
                         checkActiveNav = true;
                    }

                    if (navItem._children && navItem._children.some((navChildItem) => pathName.includes(navChildItem.to))) {
                         newActiveSubNavItem = navItem.name;
                         checkActiveNav = true;
                    }

                    if (
                         navItem._children &&
                         navItem._children[0] &&
                         navItem._children[0]._children &&
                         navItem._children[0]._children.some((navChildItem) => pathName.includes(navChildItem.to))
                    ) {
                         newActiveSubNavItem = navItem.name;
                         checkActiveNav = true;
                    }

                    if (navItem.data && navItem.data.some((group) => group.length > 1 && group.slice(1).some((item) => pathName.includes(item.to)))) {
                         newActiveSubNavItem = navItem.name;
                         checkActiveNav = true;
                    }
               }

               if (checkActiveNav) {
                    switch (navKey) {
                         case 'subscriberDataNav':
                              activeNavKey = subscriberNavKey.LISTENERS;
                              break;
                         case 'subscriberConsentPrivacyNav':
                              activeNavKey = subscriberNavKey.CONSENT_PRIVACY;
                              break;
                         case 'subscriberDestinationNav':
                              activeNavKey = subscriberNavKey.DESTINATIONS;
                              break;
                         case 'subscriberMonitoringNav':
                              activeNavKey = subscriberNavKey.MONITORING;
                              break;
                         case 'subscriberAnalyticsNav':
                              activeNavKey = subscriberNavKey.INSIGHTS;
                              break;
                         case 'subscriberGoals':
                              activeNavKey = subscriberNavKey.GOALS;
                              break;
                         default:
                              break;
                    }
               }
          }

          if (activeNavKey) {
               setActiveMainNavItem(activeNavKey);
               setActiveSubNavItem(newActiveSubNavItem);
               handleToggleSidebar({
                    activeMainNavItem: activeNavKey,
                    toggleShowNavChild: true,
                    forceShowNavChild: true,
                    toggleMinimizeNav: showTour ? false : true,
                    forceMinimizeNav: showTour ? false : true,
               });
               // checkNavChild()
          } else {
               setActiveMainNavItem(null);
               dispatch(setNewLayout({ activeMainNavItem: null }));
               setActiveSubNavItem(null);
               handleToggleSidebar({ toggleShowNavChild: true, forceShowNavChild: false });
               // checkNavChild()
          }
     };

     useEffect(() => {
          const unlisten = history.listen(() => {
               const pathName = history.location.pathname;
               if (pathName) {
                    if (showPreviewBrowserPopup) {
                         dispatch(setPreviewBrowserRule({ showPopup: false }));
                    }

                    if (pathName === `/${reportId.secondId}`) {
                         executeSidebarScroll();
                    } else {
                         handleShowActiveNavItem(pathName);
                    }
               }
          });

          return () => unlisten(); // eslint-disable-next-line
     }, [subscriberDataNav, subscriberConsentPrivacyNav, subscriberDestinationNav, subscriberMonitoringNav, subscriberAnalyticsNav]); // eslint-disable-line react-hooks/exhaustive-deps

     // Show Active Listeners dropdown when enable listener
     useEffect(() => {
          if (elementJustActivated) {
               setMenuItemActive(null);
               setActiveDropdown(elementJustActivated.type);

               const timerId = setTimeout(() => {
                    setMenuItemActive(elementJustActivated.type);
               }, 0);

               return () => {
                    clearTimeout(timerId);
               };
          }
     }, [elementJustActivated]); // eslint-disable-line react-hooks/exhaustive-deps

     useEffect(() => {
          if (showTour && [...TOUR_FORCE_OPEN.menuLv1, ...TOUR_FORCE_OPEN.menuLv2].includes(stepTour)) {
               switch (stepTour) {
                    case TOURSTEPS.menuActiveListeners.orderNumber:
                         setMenuItemActive('Active Listeners');
                         break;
                    case TOURSTEPS.menuCustomData.orderNumber:
                         setMenuItemActive('Custom Data Rules');
                         break;
                    case TOURSTEPS.menuActiveDestinations.orderNumber:
                    case TOURSTEPS.mainMenuDestination.orderNumber:
                         setMenuItemActive(subscriberNavKey.DESTINATIONS);
                         break;
                    case TOURSTEPS.mainMenuMonitoring.orderNumber:
                         setMenuItemActive('Automatic Monitoring');
                         break;
                    default:
                         setMenuItemActive('');
                         break;
               }
          }
     }, [showTour, stepTour]); // eslint-disable-line react-hooks/exhaustive-deps

     const getLogoComponent = useCallback(() => {
          if (showMyAccount) {
               return <CIcon className="sidebar-brand-full" icon="logo-dark" height={35} />;
          } else {
               return (
                    <>
                         <CIcon className="sidebar-brand-full hide minimize-nav" icon="favicon" height={21} />
                         <CIcon className="sidebar-brand-full full-logo" icon="logo-light" height={35} />
                    </>
               );
          }
     }, [showMyAccount]);

     const showToggleSidebar = !showMyAccount && activeMainNavItem && activeMainNavItem !== '';

     useEffect(() => {
          const handleScroll = (e) => {
               if (e.target.scrollTop > 0) {
                    dispatch(
                         setScrollSidebar({
                              showScrollTop: true,
                         })
                    );
               }
               if (e.target.scrollTop === 0) {
                    dispatch(
                         setScrollSidebar({
                              showScrollDown: false,
                         })
                    );
               }
               setScrollTop(e.target.scrollTop);
          };

          const element = document.querySelector('.sidebar-items-wrap');

          if (element) {
               element.addEventListener('scroll', handleScroll);
               return () => {
                    element.removeEventListener('scroll', handleScroll);
               };
          }
     }, [scrollTop, showScrollDown, showScrollTop]); // eslint-disable-line

     const handleComponentClick = () => {
          if (activeMainNavItem === subscriberNavKey.LISTENERS) {
               setActiveComponent(navigationListListenersRef);
          }
          if (activeMainNavItem === subscriberNavKey.INSIGHTS) {
               setActiveComponent(navigationListAnalyticsRef);
          }
          if (activeMainNavItem === subscriberNavKey.CONSENT_PRIVACY) {
               setActiveComponent(navigationListConsentPrivacyRef);
          }
          if (activeMainNavItem === subscriberNavKey.DESTINATIONS) {
               setActiveComponent(navigationListDestinationsRef);
          }
          if (activeMainNavItem === subscriberNavKey.MONITORING) {
               setActiveComponent(navigationListMonitoringRef);
          }
     };

     useEffect(() => {
          handleComponentClick();
     }, [activeMainNavItem]); // eslint-disable-line

     // useResizeObserver(activeComponent, handleResize);

     useEffect(() => {
          const elListener = document.querySelector('.sidebar-lv2 .navigation-list .sidebar-nav-dropdown:nth-child(1)');
          const el = document.querySelector('.sidebar-lv2 .navigation-list .sidebar-nav-dropdown:nth-child(2)');
          if (stepTour === TOURSTEPS.menuActiveListeners.orderNumber) {
               elListener.classList.add('c-show');
               el.classList.remove('c-show');
          }

          if (stepTour === TOURSTEPS.menuCustomData.orderNumber) {
               el.classList.add('c-show');
               elListener.classList.remove('c-show');
          }
     }, [showTour, stepTour]);

     const listUrlInsights = useMemo(() => extractToAttributes(subscriberAnalyticsNav), [subscriberAnalyticsNav]);
     const listUrlGoals = useMemo(() => extractToAttributes(subscriberGoals), [subscriberGoals]);
     const listUrlSources = useMemo(() => extractToAttributes(subscriberDataNav), [subscriberDataNav]);
     const listUrlDestinations = useMemo(() => extractToAttributes(subscriberDestinationNav), [subscriberDestinationNav]);
     const listUrlHealth = useMemo(() => extractToAttributes(subscriberMonitoringNav), [subscriberMonitoringNav]);
     const listUrlConsent = useMemo(() => extractToAttributes(subscriberConsentPrivacyNav), [subscriberConsentPrivacyNav]);

     // const overFlowScroll = activeMainNavItem === subscriberNavKey.INSIGHTS || activeMainNavItem === subscriberNavKey.LISTENERS;
     return (
          <CSidebar
               onVisibleChange={(val) => dispatch(setSidebarShow(val))}
               className={`${showMyAccount ? 'sidebar-left subscriber-my-account-sidebar' : 'sidebar-v2'}${
                    reduxForceShowNavChild ? ' show-nav-child' : ''
               }${showTour ? ' tour-element' : ''}${indexTour ? ' tour-destination' : ''}${
                    [...TOUR_FORCE_OPEN.menuLv1, ...TOUR_FORCE_OPEN.menuLv2].includes(stepTour) ? ' highlight' : ''
               }${TOUR_FORCE_OPEN.menuLv2Highlight.includes(stepTour) ? ' nav-highlight-child' : ''}`}
               position="fixed"
               placement="start"
               colorScheme="dark"
               size="lg"
          >
               <div className="sidebar-top d-flex justify-content-between align-item-center">
                    <CSidebarBrand className="d-md-down-none" to={`/${reportId.secondId}`} as={Link} style={{ display: 'flex', flex: '0 0 56px', alignItems: 'center' }}>
                         {getLogoComponent()}
                         {/* <CIcon className="sidebar-brand-minimized" icon="sygnet" height={35} /> */}
                    </CSidebarBrand>
                    {showToggleSidebar && (
                         <CToggler
                              inHeader
                              className="d-md-down-none toggle-sidebar"
                              onClick={() => handleToggleSidebar({ toggleShowNavChild: true })}
                         >
                              <CIcon icon="left-hamburger" />
                         </CToggler>
                    )}
               </div>

               {showMyAccount ? (
                    <SubscriberMyAccountSidebar />
               ) : (
                    activeAccount.id && (
                         <div className="sidebar-content-wrap">
                              <div className="sidebar-nav-list sidebar-lv1">
                                   <CSidebarNav style={{ padding: 0, marginLeft: '-1px' }}>
                                        <div ref={sidebarRef}></div>
                                        <CCreateElement
                                             items={subscriberSidebarNav}
                                             components={{
                                                  CSidebarNavItem,
                                             }}
                                        />
                                   </CSidebarNav>

                                   <CSidebarNav className="system-utilization">
                                        <CNavItem className="btn-packages">
                                             <CButton
                                                  className="rounded-pill"
                                                  style={{
                                                       '--cui-btn-color': '#ffff',
                                                       '--cui-btn-hover-color': '#ffff',
                                                       lineHeight: 1.5,
                                                       fontSize: '.875rem',
                                                  }}
                                                  color="success w-100"
                                                  onClick={onClickUpgrade}
                                             >
                                                  Upgrade
                                             </CButton>
                                             <CButton
                                                  className="btn-compare text-capitalize"
                                                  style={{ '--cui-btn-active-border-color': 'transparent' }}
                                                  onClick={onClickUpgrade}
                                             >
                                                  Compare Plans
                                             </CButton>
                                        </CNavItem>
                                   </CSidebarNav>

                                   <div className="minimize-sidebar">
                                        <div className="wrap">
                                             <CIcon
                                                  className="arrow-area"
                                                  icon="arrowArea"
                                                  onClick={() => handleToggleSidebar({ toggleMinimizeNav: true })}
                                             />
                                             <CIcon
                                                  className="minimize-icon"
                                                  icon="iconDoubleCaret"
                                                  onClick={() => handleToggleSidebar({ toggleMinimizeNav: true })}
                                             />
                                        </div>
                                   </div>
                              </div>
                              <div className={`sidebar-nav-list sidebar-lv2`}>
                                   <div className={`sidebar-items-wrap scroll-y`} style={{ overflowX: 'hidden' }} ref={customScrollRef}>
                                        <div
                                             className={`navigation-list sources-nav ${
                                                  activeMainNavItem === subscriberNavKey.LISTENERS ? 'show' : ''
                                             }`}
                                             ref={navigationListListenersRef}
                                             style={{ '--scrollY': `${scrollDistance ? scrollDistance : 0}px` }}
                                        >
                                             <CCreateElement
                                                  items={subscriberDataNav}
                                                  components={{
                                                       CSidebarNavDropdown,
                                                       CSidebarNavItem,
                                                       CSidebarNavDropdownCustom,
                                                       CNavItemGroups,
                                                  }}
                                             />
                                        </div>
                                        <div
                                             className={`navigation-list ${activeMainNavItem === subscriberNavKey.CONSENT_PRIVACY ? 'show' : ''}`}
                                             ref={navigationListConsentPrivacyRef}
                                        >
                                             <CCreateElement
                                                  items={subscriberConsentPrivacyNav}
                                                  components={{
                                                       CSidebarNavDropdown,
                                                       CSidebarNavItem,
                                                  }}
                                             />
                                        </div>
                                        <div
                                             className={`navigation-list ${activeMainNavItem === subscriberNavKey.DESTINATIONS ? 'show' : ''}`}
                                             ref={navigationListDestinationsRef}
                                        >
                                             <CCreateElement
                                                  items={subscriberDestinationNav}
                                                  components={{
                                                       CSidebarNavDropdown,
                                                       CSidebarNavItem,
                                                       CSidebarNavDropdownCustom,
                                                  }}
                                             />
                                        </div>
                                        <div
                                             className={`navigation-list ${activeMainNavItem === subscriberNavKey.GOALS ? 'show' : ''}`}
                                             ref={navigationListGoals}
                                        >
                                             <CCreateElement
                                                  items={subscriberGoals}
                                                  components={{
                                                       CSidebarNavDropdown,
                                                       CSidebarNavItem,
                                                       CSidebarNavDropdownCustom,
                                                  }}
                                             />
                                        </div>
                                        <div
                                             className={`navigation-list ${activeMainNavItem === subscriberNavKey.MONITORING ? 'show' : ''}`}
                                             ref={navigationListMonitoringRef}
                                        >
                                             <CCreateElement
                                                  items={subscriberMonitoringNav}
                                                  components={{
                                                       CSidebarNavDropdown,
                                                       CSidebarNavItem,
                                                       CSidebarNavDropdownCustom,
                                                  }}
                                             />
                                        </div>
                                        <div
                                             className={`navigation-list insights-nav ${
                                                  activeMainNavItem === subscriberNavKey.INSIGHTS ? 'show' : ''
                                             }`}
                                             ref={navigationListAnalyticsRef}
                                             style={{ '--scrollY': `${scrollDistance ? scrollDistance : 0}px`, minHeight: '90vh' }}
                                        >
                                             <div
                                                  className="wrapperAnalytics"
                                                  style={{
                                                       display: 'flex',
                                                       flexDirection: 'column',
                                                       justifyContent: 'space-between',
                                                       minHeight: '94vh',
                                                  }}
                                             >
                                                  <div style={{ zIndex: '0' }}>
                                                       <CCreateElement
                                                            items={subscriberAnalyticsNav}
                                                            components={{
                                                                 CSidebarNavDropdown,
                                                                 CSidebarNavItem,
                                                                 CSidebarNavDropdownCustom,
                                                            }}
                                                       />
                                                  </div>
                                                  <CCreateElement
                                                       items={[
                                                            {
                                                                 _tag: 'CSidebarNavDropdownCustom',
                                                                 name: 'Data Settings',
                                                                 icon: 'iconGear',
                                                                 className: 'nav-sticky',
                                                                 _children: [
                                                                      {
                                                                           _tag: 'CSidebarNavItem',
                                                                           name: 'Metrics & Dimensions',
                                                                           to: SUBSCRIBER_PATH.INSIGHTS_METRICS_AND_DIMENSIONS.replace(':secondId', reportId.secondId),
                                                                           exact: false,
                                                                      },
                                                                      {
                                                                           _tag: 'CSidebarNavItem',
                                                                           name: 'Channels',
                                                                           to: SUBSCRIBER_PATH.INSIGHTS_CHANNELS.replace(':secondId', reportId.secondId),
                                                                           exact: false,
                                                                      },
                                                                      {
                                                                           _tag: 'CSidebarNavItem',
                                                                           name: 'Segments',
                                                                           to: SUBSCRIBER_PATH.SEGMENT.replace(':secondId', reportId.secondId),
                                                                           exact: false,
                                                                      },
                                                                      {
                                                                           _tag: 'CSidebarNavItem',
                                                                           name: 'Custom Data Sets',
                                                                           to: SUBSCRIBER_PATH.INSIGHTS_CUSTOM_DATA_SETS.replace(':secondId', reportId.secondId),
                                                                           exact: false,
                                                                      },
                                                                 ],
                                                                 show: menuItemActive === 'Data Settings',
                                                            },
                                                       ]}
                                                       components={{
                                                            CSidebarNavDropdown,
                                                            CSidebarNavItem,
                                                            CSidebarNavDropdownCustom,
                                                       }}
                                                  />
                                             </div>
                                        </div>
                                   </div>
                              </div>
                         </div>
                    )
               )}
          </CSidebar>
     );
};

export default React.memo(SubscriberSidebarV2);
